// src/components/PasswordForm.js
import React from 'react';
import { Button } from 'antd';

const PasswordForm = ({ password, onPasswordChange, onSubmit, errorMessage }) => {
  return (
    <div className="form-container">
      <form onSubmit={onSubmit}>
        <label>
          Entrez la date :
          <input 
            type="password" 
            value={password} 
            onChange={onPasswordChange} 
            className="input-field"
          />
        </label>
        <Button type="primary" htmlType="submit" className="submit-button">Valider</Button>
      </form>
      {errorMessage && <div className="message">{errorMessage}</div>}
    </div>
  );
};

export default PasswordForm;

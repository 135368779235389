// src/components/Captcha.js
import React, { useState, useEffect } from 'react';
import { Button, message } from 'antd';
import './Captcha.css';

const animals = ['🐶', '🐱', '🦓', '🦒', '🦘', '🐢', '🐍', '🦕', '🦖', '🐎', '🐭', '🦊', '🦁', '🐮', '🐷', '🐸', '🐵', '🐥', '🦆', '🦉', '🦅'];
const ducks = ['🦆', '🐥'];

const shuffleArray = (array) => array.sort(() => Math.random() - 0.5);

const Captcha = ({ onSuccess, externalDuckClicked }) => {
  const [selectedAnimals, setSelectedAnimals] = useState([]);
  const [shuffledAnimals, setShuffledAnimals] = useState(shuffleArray([...animals]));

  const handleAnimalClick = (animal) => {
    if (selectedAnimals.includes(animal)) return;
    setSelectedAnimals([...selectedAnimals, animal]);

    if (ducks.includes(animal)) {
      const selectedDucks = selectedAnimals.filter((a) => ducks.includes(a)).length + 1;
      if (selectedDucks === ducks.length && externalDuckClicked) {
        message.success('Captcha réussi !');
        onSuccess();
      }
    } else {
      message.error('Ce n\'est pas un canard !');
      setShuffledAnimals(shuffleArray([...animals]));  // Remélanger la liste des animaux
      setSelectedAnimals([]);  // Réinitialiser les animaux sélectionnés
    }
  };

  useEffect(() => {
    console.log(externalDuckClicked)
    if (externalDuckClicked) {
      const selectedDucks = selectedAnimals.filter((a) => ducks.includes(a)).length;
      if (selectedDucks === ducks.length) {
        message.success('Captcha réussi !');
        onSuccess();
      }
    }
  }, [externalDuckClicked, selectedAnimals, onSuccess]);

  return (
    <div className="captcha-container">
      <p>Sélectionnez tous les canards :</p>
      <div className="captcha-grid">
        {shuffledAnimals.map((animal, index) => (
          <Button 
            key={index} 
            className="captcha-button" 
            onClick={() => handleAnimalClick(animal)}
            disabled={selectedAnimals.includes(animal)}
          >
            {animal}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default Captcha;

// src/context/DuckContext.js
import {createContext, useState, useContext, useEffect} from 'react';

const DuckContext = createContext();

export const DuckProvider = ({ children }) => {
  const [externalDuckClicked, setExternalDuckClicked] = useState(false);

  useEffect(() => {
    // Cette fonction est appelée chaque fois que myVariable change
    console.log('La valeur de externalDuckClicked a changé:', externalDuckClicked);
  }, [externalDuckClicked]); // La dépendance est myVariable

  return (
    <DuckContext.Provider value={{ externalDuckClicked, setExternalDuckClicked }}>
      {children}
    </DuckContext.Provider>
  );
};

export const useDuck = () => {
  return useContext(DuckContext);
};

// src/pages/EscapeGameHome.js
import React, { useState, useEffect } from "react";
import PasswordForm from "./PasswordForm/PasswordForm";
import ScientistNoteButton from "./ScientitsNoteButton/ScientitsNoteButton";
import Captcha from './Captcha/Captcha';
import "./EscapeGame.css";
import { useDuck } from './context/DuckContext';
import transition from "../../transition"


const EscapeGame = () => {
  const [password, setPassword] = useState('');
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);
  const [captchaCompleted, setCaptchaCompleted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { externalDuckClicked } = useDuck();

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === '1966') {
      setIsPasswordCorrect(true);
      setErrorMessage('');
    } else {
      setErrorMessage('∏π∏≈††π ∏£∞≈††π√†, ∏π∏ incorrect');
    }
  };

  const handleCaptchaSuccess = () => {
    setCaptchaCompleted(true);
  };

  return (
    <div className="containerEscape">
      {!isPasswordCorrect ? (
        <PasswordForm
          password={password}
          onPasswordChange={handlePasswordChange}
          onSubmit={handlePasswordSubmit}
          errorMessage={errorMessage}
        />
      ) : !captchaCompleted ? (
        <Captcha onSuccess={handleCaptchaSuccess} externalDuckClicked={externalDuckClicked} />
      ) : (
        <ScientistNoteButton />
      )}
    </div>
  );
};
export default transition(EscapeGame);

// src/components/ScientistNoteButton.js
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Button, message } from 'antd';
import ModeDemploiMachinePDF from './ModeDemploiMachine.pdf'; // Import du fichier PDF

const ScientistNoteButton = () => {
  const [buttonPosition, setButtonPosition] = useState({ x: 0, y: 0 });
  const [canMove, setCanMove] = useState(true);
  const [keysPressed, setKeysPressed] = useState({ S: false, T: false, O: false, P: false });

  const handleMouseEnter = () => {
    if (canMove) {
      const newX = Math.random() * 400 - 200;
      const newY = Math.random() * 400 - 200;
      setButtonPosition({ x: newX, y: newY });
    }
  };

  const handleButtonClick = () => {
    if (!canMove) {
      const link = document.createElement('a');
      link.href = ModeDemploiMachinePDF; // Utilisation du chemin importé
      link.download = 'ModeDemploiMachine.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      message.warning('Je suis bien trop rapide pour toi');
    }
  };

  const handleKeyDown = (e) => {
    const key = e.key.toUpperCase();
    if (['S', 'T', 'O', 'P'].includes(key)) {
      setKeysPressed((prevKeys) => ({ ...prevKeys, [key]: true }));
    }
  };

  const handleKeyUp = (e) => {
    const key = e.key.toUpperCase();
    if (['S', 'T', 'O', 'P'].includes(key)) {
      setKeysPressed((prevKeys) => ({ ...prevKeys, [key]: false }));
    }
  };

  useEffect(() => {
    if (keysPressed.S && keysPressed.T && keysPressed.O && keysPressed.P) {
      setCanMove(false);
    } else {
      setCanMove(true);
    }
  }, [keysPressed]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  return (
    <motion.div
      style={{ position: 'relative', display: 'inline-block' }}
      animate={{ x: buttonPosition.x, y: buttonPosition.y }}
      transition={{ type: 'spring', stiffness: 300 }}
    >
      <Button 
        type="primary" 
        className="scientist-note-button" 
        onMouseEnter={handleMouseEnter}
        onClick={handleButtonClick}
      >
        Mode d'emploi de la machine
      </Button>
    </motion.div>
  );
};

export default ScientistNoteButton;
